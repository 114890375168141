import i18n from 'i18n';
import * as Yup from 'yup';
import { PAYMENT_PROVIDERS } from 'components/common/constants';
import { passwordRegExp } from 'services/utils';

export const TIMEZONE = 'Europe/London';

const dateOpts = {
  timeZone: TIMEZONE,
  year: 'numeric',
  month: 'short',
  day: '2-digit'
};

const timeOpts = {
  timeZone: TIMEZONE,
  hour: '2-digit',
  minute: '2-digit'
};

export const parseDate = (data) => {
  const date = new Date(data);

  return {
    date: date.toLocaleString('en-UK', dateOpts),
    time: date.toLocaleString('en-UK', timeOpts) + ' GB'
  };
};

export const getAccountTitle = (account) => {
  return account.transfer_provider === PAYMENT_PROVIDERS.WALLESTER
    ? i18n.getMessage('account.title.cardAccount', { accountNumber: account.wallet_number })
    : account.transfer_provider === PAYMENT_PROVIDERS.MANUAL
    ? i18n.getMessage('account.title.manual', { accountNumber: account.wallet_number })
    : account.multi_wallet_number
    ? account.multi_iban?.iban || account.multi_wallet_number
    : account.iban?.iban || account.wallet_number;
};

export const getAccountsOptionsForSelect = (accounts) =>
  accounts?.map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account?.wallet_number,
      value: title,
      currency: account?.currency
    };
  });

export const extractBaseUrl = (url) => {
  const parts = url.split('/');
  return `/${parts.slice(1, 2).join('/')}`;
};

export const getPasswordValidation = () => {
  return Yup.string()
    .nullable(true)
    .min(7, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
    .max(30, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
    .required(i18n.getMessage('error.passwordEmpty'))
    .matches(passwordRegExp, { message: i18n.getMessage('error.PASSWORD_REQUIREMENTS') });
};

export const isFullSecurityCode = (code) => {
  const regex = /^\w{6}$/;
  return regex.test(code);
};

export const hasMultipleCurrencies = (userAccounts) => {
  if (userAccounts.length < 2) return false;

  const uniqueCurrencies = new Set();

  for (const account of userAccounts) {
    uniqueCurrencies.add(account.currency);
  }

  return uniqueCurrencies.size > 1;
};

export const saveAllParamsToLocalStorage = (params) => {
  const allParams = Array.from(params.entries()).reduce((accumulator, [key, value]) => {
    accumulator[key] = value;
    return accumulator;
  }, {});
  localStorage.setItem('allParams', JSON.stringify(allParams));
};

export const getAllParamsFromLocalStorage = () => {
  const storedParams = localStorage.getItem('allParams');
  return storedParams ? JSON.parse(storedParams) : {};
};

export const clearAllParamsFromLocalStorage = () => {
  localStorage.removeItem('allParams');
};
