import * as Yup from 'yup';
import { PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import {
  LATIN_LETTERS_REG_EXP,
  LATIN_LETTERS_AND_DIGITS_REG_EXP,
  START_WITH_GB_REG_EXP,
  getLatinLettersDigitsSymbolsRegExp
} from './utils';

export default ({ i18n, onChange, onSubmit, customerNumber, model, currency, paymentMethod, previousTransaction }) => ({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
    amount: `${previousTransaction?.amount || ''}`,
    recipient_wallet: previousTransaction?.counterparty?.iban || '',
    recipient_type: RECIPIENT_TYPES[previousTransaction?.recipient_type] || RECIPIENT_TYPES.COMPANY,
    company_name: previousTransaction?.counterparty?.party_name,
    first_name: previousTransaction?.counterparty?.party_name.split(' ')[0] || null,
    last_name: previousTransaction?.counterparty?.party_name.split(' ')[1] || null,
    comment: previousTransaction?.comment,
    documents: null
  },
  initialStatus: { company_name: true, recipient_wallet: true },
  validationSchema: Yup.object().shape(
    {
      recipient_wallet: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .max(34, i18n.getMessage('client.validation.message.maxSize', { size: 34 }))
        .trim()
        // trick for using `currency` variable in checking value in validation
        .when('recipient_type', (recipient_type, schema) => {
          if (currency === 'GBP' && paymentMethod !== PAYMENT_METHOD.SWIFT) {
            return schema.matches(START_WITH_GB_REG_EXP, {
              message: i18n.getMessage('client.validation.message.startWithGB')
            });
          }
        })
        .matches(LATIN_LETTERS_AND_DIGITS_REG_EXP, {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersAndDigits')
        }),
      amount: Yup.string().nullable(true).required(i18n.getMessage('client.validation.message.isEmpty')),
      recipient_type: Yup.string().nullable(false).required(i18n.getMessage('client.validation.message.isEmpty')),
      company_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === 'Company') {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(getLatinLettersDigitsSymbolsRegExp('.,-'), {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
                  availableSymbols: '“.“, “,“, “-“'
                })
              });
          }
        }),
      first_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      last_name: Yup.string()
        .nullable(true)
        .when('recipient_type', (recipient_type, schema) => {
          if (recipient_type === RECIPIENT_TYPES.INDIVIDUAL) {
            return schema
              .nullable(true)
              .required(i18n.getMessage('client.validation.message.isEmpty'))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              });
          }
        }),
      comment: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('client.validation.message.isEmpty'))
        .matches(getLatinLettersDigitsSymbolsRegExp('.,-/#//"//"/'), {
          message: i18n.getMessage('client.validation.message.isOnlyLatinLettersDigitsSymbols', {
            availableSymbols: '“.“, “,“, “-“, “ ““ “, “#“'
          })
        })
        .max(140, i18n.getMessage('client.validation.message.maxSize', { size: 140 }))
    },
    [['account_number', 'recipient_wallet']]
  ),
  onSubmit: (values) => {
    onChange(values);
    onSubmit({ customerNumber, providerData: values, model });
  }
});
