import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import useDeviceCheck from 'services/useDeviceCheck';
import { classNames } from '../utils';
import './IconButton.scss';
import { ROUTE_PATHS } from '../../routes/constants';

export const IconButton = ({
  type = 'button',
  Icon,
  text,
  onClick,
  path,
  navigationType,
  active,
  className,
  isDisabled,
  unreadTopics
}) => {
  const isMobileDevice = useDeviceCheck();
  const IconButtonClasses = classNames({
    'icon-button': true,
    active: active,
    disabled: isDisabled,
    [className]: true
  });

  const handleClick = (e) => {
    if (isDisabled) e.preventDefault();
    if (onClick) onClick();
  };

  if (navigationType) {
    return (
      <NavLink className={IconButtonClasses} to={{ pathname: path }} onClick={handleClick}>
        {unreadTopics && unreadTopics > 0 && path === ROUTE_PATHS.MESSAGES ? (
          <div className={'message-icon-wrapper'}>
            <div className={'unread-message-count'}>{unreadTopics}</div>
            {Icon && <Icon />}
          </div>
        ) : (
          Icon && <Icon />
        )}
        <p className={'test'}>{text}</p>
      </NavLink>
    );
  }

  return (
    <button
      type={type}
      className={IconButtonClasses}
      onClick={isMobileDevice ? null : handleClick}
      disabled={isDisabled}
      onTouchEnd={isMobileDevice ? handleClick : null}
    >
      {Icon && <Icon />}
      <p>{text}</p>
    </button>
  );
};

IconButton.propTypes = {
  type: PropTypes.string,
  Icon: PropTypes.object,
  text: PropTypes.string,
  navigationType: PropTypes.bool,
  path: PropTypes.string,
  active: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  unreadTopics: PropTypes.number
};
