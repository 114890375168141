/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { FORM_TYPES } from '../../constants';
import { LPBSimpleValidation, LPBAdvancedValidation } from './ModelValidators';
import { LPBSimpleModel, LPBAdvancedModel } from './Models';
import LPBSimpleForm from './LPBSimpleForm';
import LPBAdvancedForm from './LPBAdvancedForm';

const LPBForm = (tableProps) => {
  const formType = tableProps.formType;

  const validationSchema =
    formType === FORM_TYPES.SIMPLE
      ? LPBSimpleValidation({ ...tableProps.validationProps, model: LPBSimpleModel })
      : LPBAdvancedValidation({ ...tableProps.validationProps, model: LPBAdvancedModel });
  const form = useFormik({ enableReinitialize: true, ...validationSchema });

  useEffect(() => {
    form.resetForm();
  }, [tableProps.account.wallet_number]);

  const formProps = { ...tableProps, validationSchema };
  return (
    <>
      {formType === FORM_TYPES.SIMPLE ? (
        <LPBSimpleForm commonForm={form} {...formProps} />
      ) : (
        <LPBAdvancedForm commonForm={form} {...formProps} />
      )}
    </>
  );
};

LPBForm.propTypes = {
  customerNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  iban: PropTypes.string,
  account: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool,
  // todo: describe validationProps object values
  validationProps: PropTypes.object.isRequired
};

export default LPBForm;
