import { useContext, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { AddRepresentativeScheme } from './AddRepresentativeScheme';
import { ErrorNoAccountScheme } from './ErrorNoAccountScheme';
import { ErrorNotVerifiedScheme } from './ErrorNotVerifiedScheme';
import { LinkedSuccessScheme } from './LinkedSuccessScheme';
import { DeleteUserScheme } from './DeleteUserScheme';
import { PermissionScheme } from './PermissionScheme';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_PERMISSIONS, USER_CUSTOMER_STATUSES } from 'components/common/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { getSecureUserPhone } from 'services/authUtils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Representatives.scss';

const getRepresentativesTableHeader = (i18n) => [
  i18n.getMessage('representatives.header.email'),
  i18n.getMessage('representatives.header.fullName'),
  i18n.getMessage('representatives.header.status'),
  i18n.getMessage('representatives.header.permission'),
  i18n.getMessage('representatives.header.Action')
];

const REPRESENTATIVE_POP_UP = {
  PERMISSION: 'change_permission',
  DELETE: 'delete_representative',
  SUCCESS: 'successfully_linked',
  ADD: 'add_new_representative',
  ERROR_NOT_VERIFIED: 'user_not_verified',
  ERROR_USER_NOT_APPLICATION: 'user_not_have_application_account',
  ERROR: 'api_error'
};

export const Representatives = ({ representativeStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const [activePopUp, setActivePopUp] = useState(null);
  const [representativeForUpdate, setRepresentativeForUpdate] = useState(null);

  const closePopUp = () => {
    setActivePopUp(null);
    setRepresentativeForUpdate(null);
    representativeStore.resetStore();
  };

  const getSuccessPopupMessage = () => {
    if (representativeStore.isAddRepresentativeSuccess) {
      return i18n.getMessage('representatives.header.message.successLinked');
    }
    if (representativeStore.isUpdateRepresentativeSuccess) {
      return i18n.getMessage('representatives.header.message.successUpdated');
    }
    if (representativeStore.isDeleteRepresentativeSuccess) {
      return i18n.getMessage('representatives.header.message.successRemoved');
    }
    return '';
  };

  const handleSetEmail = async (email) => {
    representativeStore.setEmail(email.trim());
    const accountInfo = await representativeStore.getAccountInfo(email.trim());
    if (!accountInfo) {
      setActivePopUp(REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION);
      return;
    }
    if (accountInfo.account_status.toUpperCase() !== USER_CUSTOMER_STATUSES.VERIFIED) {
      setActivePopUp(REPRESENTATIVE_POP_UP.ERROR_NOT_VERIFIED);
      return;
    }
    if (representativeStore?.error?.code === 'EMAIL_BELONGS_TO_CORPORATE_ACCOUNT') {
      setActivePopUp(REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION);
      return;
    }
    setActivePopUp(REPRESENTATIVE_POP_UP.PERMISSION);
  };

  const handlePermissionConfirm = async (permissions, securityCode) => {
    let updatedRepresentative;
    updatedRepresentative = await (!representativeForUpdate
      ? (updatedRepresentative = await representativeStore.addNewRepresentative({
          customerNumber: userStore.userData.account?.account_number,
          permissions,
          securityCode
        }))
      : representativeStore.updateNewRepresentative({
          customerNumber: userStore.userData.account?.account_number,
          id: representativeForUpdate.id,
          permissions,
          securityCode
        }));
    userStore.updateRepresentativeData(updatedRepresentative);
    setActivePopUp(REPRESENTATIVE_POP_UP.SUCCESS);
  };

  const handleAcceptRemove = async (securityCode) => {
    await representativeStore.removeNewRepresentative({
      customerNumber: userStore.userData.account?.account_number,
      id: representativeForUpdate.id,
      securityCode
    });
    userStore.updateRepresentativeData(representativeForUpdate, true);
    setActivePopUp(REPRESENTATIVE_POP_UP.SUCCESS);
  };

  const renderPopUpScheme = () => {
    switch (activePopUp) {
      case REPRESENTATIVE_POP_UP.ADD: {
        return (
          <AddRepresentativeScheme
            addNewClick={handleSetEmail}
            closePopUp={closePopUp}
            error={representativeStore.error}
          />
        );
      }
      case REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION: {
        return <ErrorNoAccountScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.ERROR_NOT_VERIFIED: {
        return <ErrorNotVerifiedScheme closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.PERMISSION: {
        return (
          <PermissionScheme
            email={representativeStore.email || representativeForUpdate?.representative_account?.email}
            submitPermissions={handlePermissionConfirm}
            permissions={userStore?.appConstants?.representativePermissions}
            closePopUp={closePopUp}
            phone={getSecureUserPhone()}
            representativeForUpdate={representativeForUpdate}
            error={representativeStore.error}
            clearError={() => representativeStore.clearConfirmationPopupError()}
          />
        );
      }
      case REPRESENTATIVE_POP_UP.SUCCESS: {
        return <LinkedSuccessScheme message={getSuccessPopupMessage()} closePopUp={closePopUp} />;
      }
      case REPRESENTATIVE_POP_UP.DELETE: {
        return (
          <DeleteUserScheme
            closePopUp={closePopUp}
            representativeMail={representativeForUpdate?.representative_account?.email}
            handleRemoveClick={handleAcceptRemove}
            message={getSuccessPopupMessage()}
            error={representativeStore.error}
            clearError={() => representativeStore.clearConfirmationPopupError()}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleChangeRepresentative = (representative) => {
    setActivePopUp(REPRESENTATIVE_POP_UP.PERMISSION);
    setRepresentativeForUpdate(representative);
  };

  const handleRemoveRepresentative = (representative) => {
    setActivePopUp(REPRESENTATIVE_POP_UP.DELETE);
    setRepresentativeForUpdate(representative);
  };

  const representativeTableHeader = getRepresentativesTableHeader(i18n);

  return (
    <section className='right-section'>
      <Container className='representatives' header={i18n.getMessage('container.representatives')}>
        <div className={`representatives-wrapper ${userStore.userData.representatives?.length ? 'list' : ''}`}>
          {userStore.userData.representatives?.length ? (
            <div className='representatives-table'>
              {!isMobile && (
                <div className='representatives-head'>
                  {representativeTableHeader.map((headColumn) => (
                    <p key={headColumn} className='head-Column'>
                      {headColumn}
                    </p>
                  ))}
                </div>
              )}
              {userStore.userData.representatives.map((representative) => (
                <div className='representatives-list-item' key={representative?.id}>
                  <div className='representatives-email'>
                    <p className='card-mobile-text'> {representativeTableHeader[0]}</p>
                    <p>{representative?.representative_account?.email}</p>
                  </div>
                  <div className='representatives-name'>
                    <p className='card-mobile-text'> {representativeTableHeader[1]}</p>
                    {/* eslint-disable-next-line max-len */}
                    <p>{`${representative?.representative_account?.first_name} ${representative?.representative_account?.last_name}`}</p>
                  </div>
                  <div className='representatives-status'>
                    <p className='card-mobile-text'> {representativeTableHeader[2]}</p>
                    <p>{representative?.representative_account?.account_status}</p>
                  </div>
                  <div className='representatives-permission'>
                    <p className='card-mobile-text'> {representativeTableHeader[3]}</p>
                    <div className='representatives-permission-text'>
                      {representative?.permissions?.map(
                        (permission) =>
                          permission !== CUSTOMER_PERMISSIONS.READ_MESSAGES && (
                            <p key={permission}>{i18n.getMessage(`representatives.permission.${permission}`)}</p>
                          )
                      )}
                    </div>
                  </div>
                  <div className='representatives-action'>
                    <p className='card-mobile-text'> {representativeTableHeader[4]}</p>
                    <div className='representatives-action-wrapper'>
                      <button
                        className='representative-action-button link-normal'
                        onClick={() => handleChangeRepresentative(representative)}
                      >
                        {i18n.getMessage('representatives.label.changePermission')}
                      </button>
                      <button
                        className='representative-action-button link-normal'
                        onClick={() => handleRemoveRepresentative(representative)}
                      >
                        {i18n.getMessage('representatives.label.deleteRepresentative')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <h2 className='representatives-header'>{i18n.getMessage('representatives.header.noRepresentative')}</h2>
              <p className='representatives-description'>
                {i18n.getMessage('representatives.noRepresentative.description', {
                  appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION
                })}
              </p>
            </>
          )}
          <Button
            size='large'
            type='outline'
            className='representatives-add-button'
            onClick={() => setActivePopUp(REPRESENTATIVE_POP_UP.ADD)}
          >
            {i18n.getMessage('representatives.label.addNewRepresentative')}
          </Button>
          <PopUp show={!!activePopUp} onClose={closePopUp}>
            {renderPopUpScheme()}
          </PopUp>
        </div>
      </Container>
    </section>
  );
};

Representatives.propTypes = {
  userStore: MobXPropTypes.observableObject,
  representativeStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  representativeStore: stores.representativeStore
}))(observer(Representatives));
