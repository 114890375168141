import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/iconButtons/edit.svg';
import { ReactComponent as InfoIcon } from 'assets/iconButtons/details.svg';
import { ReactComponent as NewPaymentIcon } from 'assets/iconButtons/new-payment-with-background.svg';
import { ReactComponent as RemoveIcon } from 'assets/iconButtons/remove.svg';
import { ReactComponent as TransactionIcon } from 'assets/iconButtons/transactions-with-background.svg';
import './ExclamationPoint.scss';

export const ActionButton = ({ onClick, iconType, tooltipText }) => {
  const getIcon = (iconType) => {
    if (iconType === 'new-payment') return <NewPaymentIcon className='exclamation-point-icon' />;
    if (iconType === 'edit') return <EditIcon className='exclamation-point-icon' />;
    if (iconType === 'remove') return <RemoveIcon className='exclamation-point-icon' />;
    if (iconType === 'transaction') return <TransactionIcon className='exclamation-point-icon' />;
    return <InfoIcon className='exclamation-point-icon' />;
  };

  return (
    <button className='exclamation-wrapper' onClick={() => onClick()}>
      {tooltipText && (
        <div className='tooltip'>
          <p className='tooltip-text'>{tooltipText}</p>
        </div>
      )}
      {getIcon(iconType)}
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  tooltipText: PropTypes.string
};
