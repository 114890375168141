import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Chat from '../components/Chat';
import NewMessageForm from '../components/NewMessageForm';
import NoMessages from '../components/NoMessages';
import Topics from '../components/Topics';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_PERMISSIONS, MESSAGE_TOPIC_PERMISSIONS } from 'components/common/constants';
import Loader from 'components/common/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import '../Messages.scss';

const MessagesDashboard = ({ userAccount, messagesStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const topicsRef = useRef(null);
  const [isAnnouncementTopic, setIsAnnouncementTopic] = useState(false);

  const clearError = () => messagesStore.clearError();
  const setIsNewTopicCreatedStatus = (status) => messagesStore.setIsNewTopicCreated(status);
  const setIsNewMessageCreated = (status) => messagesStore.setIsNewMessageCreated(status);
  const setNewTopicData = (subject, message) => messagesStore.setNewTopicData(subject, message);
  const setNewMessageData = (message) => messagesStore.setNewMessageData(message);
  const setSecurityCode = (code) => messagesStore.setSecurityCode(code);
  const clearUploadedFiles = () => messagesStore.clearUploadedFiles();
  const removeAttachment = (fileId) => messagesStore.removeAttachment(fileId);
  const getCurrentTopicMessagesFilesUrls = async () => {
    await messagesStore.getCurrentTopicMessagesFilesUrls(userAccount?.account_number);
  };
  const downloadAttachment = async (fileId) => {
    await messagesStore.downloadAttachment(userAccount?.account_number, fileId);
  };
  const generateSecurityCode = async () => await messagesStore.generateMessageSecurityCode();
  const resendSecurityCode = async () => await messagesStore.resendMessageSecurityCode();
  const createNewTopic = async () => await messagesStore.createNewTopic(userAccount?.account_number);
  const createNewMessage = async (topicId) => {
    await messagesStore.createNewMessage(userAccount?.account_number, topicId);
  };

  const handleClickNewMessageButton = () => {
    navigate(ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC);
  };

  const handleChangeActiveTopic = async (topicId) => {
    navigate(`/messages/${topicId}`);
  };

  useEffect(() => {
    if (messagesStore.isNewTopicCreated && messagesStore.topics.length > 0) {
      handleChangeActiveTopic(messagesStore.topics[0]?.id);
      setIsNewTopicCreatedStatus(false);
    }
  }, [messagesStore.isNewTopicCreated, messagesStore.topics]);

  useEffect(() => {
    if (messagesStore.topics.length > 0 && messagesStore.currentTopicIndex !== -1) {
      if (messagesStore.topics[messagesStore.currentTopicIndex].permission === MESSAGE_TOPIC_PERMISSIONS.ANNOUNCEMENT) {
        setIsAnnouncementTopic(true);
      } else {
        setIsAnnouncementTopic(false);
      }
    }
  }, [messagesStore.topics, messagesStore.currentTopicIndex]);

  const isNoMessage = messagesStore.topics.length === 0 && !messagesStore.isLoading;
  const isTopics = !isMobile || (isMobile && location.pathname === ROUTE_PATHS.MESSAGES);
  const isChat = messagesStore.currentTopicIndex !== -1;
  const isCreateNewTopicForm = location.pathname === ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC;

  const showWriteNewMessage = userStore.hasPermissions([CUSTOMER_PERMISSIONS.CREATE_MESSAGE]);

  return (
    <Container
      className={!isMobile ? 'container-max-height' : ''}
      header={i18n.getMessage('navigation.messages.text')}
      isMaxWidthUnset={true}
    >
      {!messagesStore.isInitialized ? (
        <div className='loader-container'>
          <Loader />
        </div>
      ) : (
        <div className='messages-wrapper'>
          {isNoMessage ? (
            <NoMessages
              handleClickNewMessageButton={handleClickNewMessageButton}
              showWriteNewMessage={showWriteNewMessage}
            />
          ) : (
            isTopics && (
              <Topics
                topics={messagesStore.topics}
                activeTopicIndex={messagesStore.currentTopicIndex}
                handleChangeActiveTopic={handleChangeActiveTopic}
                handleClickNewMessageButton={handleClickNewMessageButton}
                topicsRef={topicsRef}
                showWriteMessageButton={showWriteNewMessage}
              />
            )
          )}
          {isCreateNewTopicForm ? (
            <NewMessageForm
              securityCode={messagesStore.securityCode}
              setSecurityCode={setSecurityCode}
              generateSecurityCode={generateSecurityCode}
              resendSecurityCode={resendSecurityCode}
              newTopicData={messagesStore.newTopic}
              setNewTopicData={setNewTopicData}
              isLoading={messagesStore.isLoading || messagesStore.isFileUploading}
              error={messagesStore.error}
              clearError={clearError}
              createNewTopic={createNewTopic}
              uploadedFiles={messagesStore.uploadedFiles}
              uploadDocuments={messagesStore.uploadAttachments(userAccount?.account_number)}
              removeAttachment={removeAttachment}
            />
          ) : (
            isChat && (
              <Chat
                topic={messagesStore.topics[messagesStore.currentTopicIndex]}
                messages={messagesStore.currentTopicMessages}
                userAccount={userAccount}
                isLoading={messagesStore.isTopicMessagesLoading || messagesStore.isFileUploading}
                error={messagesStore.error}
                clearError={clearError}
                securityCode={messagesStore.securityCode}
                setSecurityCode={setSecurityCode}
                generateSecurityCode={generateSecurityCode}
                resendSecurityCode={resendSecurityCode}
                setNewMessageData={setNewMessageData}
                createNewMessage={createNewMessage}
                uploadedFiles={messagesStore.uploadedFiles}
                uploadDocuments={messagesStore.uploadAttachments(userAccount?.account_number)}
                removeAttachment={removeAttachment}
                getCurrentTopicMessagesFilesUrls={getCurrentTopicMessagesFilesUrls}
                downloadAttachment={downloadAttachment}
                topicsRef={topicsRef}
                canWriteMessages={showWriteNewMessage && !isAnnouncementTopic}
                clearUploadedFiles={clearUploadedFiles}
                isNewMessageCreated={messagesStore.isNewMessageCreated}
                setIsNewMessageCreated={setIsNewMessageCreated}
              />
            )
          )}
        </div>
      )}
    </Container>
  );
};

MessagesDashboard.propTypes = {
  userAccount: MobXPropTypes.observableObject,
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  userAccount: stores.userStore.userData.account,
  messagesStore: stores.messagesStore
}))(observer(MessagesDashboard));
