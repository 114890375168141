import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import i18nContext from 'components/i18n-context';
import { CardExample } from '../../CardExample';
import { getCardsPasswordValidation } from '../../../utils';
import {
  CUSTOMER_TYPE,
  CARD_CREATION_FORM_TYPE,
  CARD_TYPE,
  CONFIRMATION_HANDLE_TYPES,
  SMS_ERROR_CODE_DISABLE
} from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { LATIN_LETTERS_REG_EXP } from 'components/common/PaymentForm/ProviderForms/ModelValidators/utils';
import { isFullSecurityCode } from 'components/utils';
import { getSecureUserPhone } from 'services/authUtils';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import '../../../Cards.scss';

export const VirtualCardForm = ({
  customerType,
  userFullName,
  getAvailableBalance,
  accountsOptions,
  sendCreateCardRequest,
  isCardCreateConfirmation,
  error,
  clearError,
  onConfirm,
  resendSecurityCode,
  dashboardSelectedAccountNumber
}) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setSecurityCode('');
    } else {
      setAlertState({ type: '', message: '' });
    }
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
    return () => {
      clearError();
    };
  }, []);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleCardCreationConfirm = () => {
    onConfirm(securityCode);
  };

  const form = useFormik({
    initialValues: {
      account: dashboardSelectedAccountNumber || accountsOptions[0].key,
      cardholderName: '',
      securePhoneNumber: customerType === CUSTOMER_TYPE.COMPANY ? process.env.REACT_APP_DEFAULT_DIAL_CODE : '',
      secureCardPassword: '',
      repeatSecureCardPassword: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      account: Yup.string().required(i18n.getMessage('cards.creationForm.validation.isRequired')),
      cardholderName:
        customerType === CUSTOMER_TYPE.COMPANY
          ? Yup.string()
              .nullable(true)
              .max(100, i18n.getMessage('cards.error.cardholderName', { size: 100 }))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              })
          : null,
      securePhoneNumber:
        customerType === CUSTOMER_TYPE.COMPANY
          ? Yup.string()
              .required(i18n.getMessage('error.fieldRequired'))
              .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
          : null,
      secureCardPassword: getCardsPasswordValidation(),
      repeatSecureCardPassword: getCardsPasswordValidation().oneOf(
        [Yup.ref('secureCardPassword')],
        i18n.getMessage('error.passwordsDontMatch')
      )
    }),
    onSubmit: (values) => {
      if (customerType === CUSTOMER_TYPE.COMPANY) {
        sendCreateCardRequest({
          walletNumber: values.account,
          password: values.secureCardPassword,
          card_type: CARD_TYPE.VIRTUAL,
          type: customerType.toUpperCase(),
          phone: values.securePhoneNumber,
          companyName: values.cardholderName
        });
      } else {
        sendCreateCardRequest({
          walletNumber: values.account,
          password: values.secureCardPassword,
          card_type: CARD_TYPE.VIRTUAL,
          type: customerType.toUpperCase()
        });
      }
    }
  });

  const { values, handleSubmit, handleChange, validateField, setFieldValue, submitCount, errors } = form;

  const handleAccountChange = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <div className='virtual-card-form-wrapper'>
      <span className='virtual-card-form-title'>{i18n.getMessage('cards.creationForm.virtual.title')}</span>
      <CardExample
        customerType={customerType}
        cardType={CARD_CREATION_FORM_TYPE.VIRTUAL}
        cardholderName={customerType === CUSTOMER_TYPE.COMPANY ? values.cardholderName : userFullName}
      />
      <form className='virtual-card-form' onSubmit={handleSubmit} autoComplete='off'>
        <span className='virtual-card-form-description'>
          {i18n.getMessage('cards.creationForm.virtual.authorizedPerson')}
        </span>
        <Alert className='virtual-card-form-alert' type='warning' message={getErrorMessageForAlert(i18n, error)} />
        <InputDropDown
          isRequired={true}
          className='virtual-card-form-account-select'
          name='account'
          topLabel={getAvailableBalance()}
          label={i18n.getMessage('cards.creationForm.label.accountNumber')}
          value={values.account}
          onChange={handleAccountChange}
          onBlur={() => validateField('account')}
          options={accountsOptions}
          isMulti={false}
        />
        {customerType === CUSTOMER_TYPE.COMPANY && (
          <>
            <Input
              className='virtual-card-form-password-input'
              label={i18n.getMessage('cards.creationForm.label.cardholderName')}
              name={'cardholderName'}
              value={values.cardholderName}
              onChange={handleChange}
              onBlur={() => validateField('cardholderName')}
              initialStatus={submitCount}
              error={errors?.cardholderName || (errors && prepareFieldErrors(i18n, errors)?.cardholderName)}
              isApiError={
                error &&
                error?.code !== 'CARD_LIMIT_PER_CUSTOMER_EXCEEDED' &&
                error?.code !== 'CARD_LIMIT_PER_ACCOUNT_EXCEEDED'
              }
            />
            <Input
              isRequired={true}
              className='virtual-card-form-password-input'
              type={'phone'}
              label={i18n.getMessage('cards.creationForm.label.securePhoneNumber')}
              name={'securePhoneNumber'}
              value={values.securePhoneNumber}
              setValue={setFieldValue}
              onChange={handleChange}
              onBlur={() => validateField('securePhoneNumber')}
              setCountryCode={setCountryCode}
              initialStatus={submitCount}
              error={errors?.securePhoneNumber || (errors && prepareFieldErrors(i18n, errors)?.securePhoneNumber)}
              isApiError={
                error &&
                error?.code !== 'CARD_LIMIT_PER_CUSTOMER_EXCEEDED' &&
                error?.code !== 'CARD_LIMIT_PER_ACCOUNT_EXCEEDED'
              }
            />
          </>
        )}
        <Input
          isRequired={true}
          className='virtual-card-form-password-input'
          type='password'
          label={i18n.getMessage('cards.creationForm.label.createSecureCardPassword')}
          name='secureCardPassword'
          value={values.secureCardPassword}
          onChange={handleChange}
          onBlur={() => validateField('secureCardPassword')}
          initialStatus={submitCount}
          error={errors.secureCardPassword}
          subText={i18n.getMessage('cards.creationForm.subtext.password', { amount: '8-36' })}
        />
        <Input
          isRequired={true}
          className='virtual-card-form-password-input'
          type='password'
          label={i18n.getMessage('cards.creationForm.label.repeatSecureCardPassword')}
          name='repeatSecureCardPassword'
          value={values.repeatSecureCardPassword}
          onChange={handleChange}
          onBlur={() => validateField('repeatSecureCardPassword')}
          initialStatus={submitCount}
          error={errors.repeatSecureCardPassword}
        />
        {isCardCreateConfirmation && (
          <ConfirmCodeWrapper
            PhoneComponent={
              <strong>
                {customerType === CUSTOMER_TYPE.COMPANY ? values.securePhoneNumber : getSecureUserPhone()}
              </strong>
            }
            isDisabled={isDisabled}
            error={!error?.type && error}
            onComplete={onComplete}
            showCountDown={showCountDown}
            handleResendCode={handleResendCode}
            onTimerEnd={onTimerEnd}
            alertState={alertState}
            isCardCreationForm={true}
            confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
          />
        )}
        {isCardCreateConfirmation ? (
          <Button
            className='virtual-card-form-button'
            type={'primary'}
            roleType={'button'}
            size={'large'}
            fullWidth={true}
            onClick={handleCardCreationConfirm}
            isDisabled={!isFullSecurityCode(securityCode)}
          >
            {i18n.getMessage('cards.creationForm.button.confirm')}
          </Button>
        ) : (
          <Button
            className='virtual-card-form-button'
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
          >
            {i18n.getMessage('cards.creationForm.button.get')}
          </Button>
        )}
      </form>
    </div>
  );
};

VirtualCardForm.propTypes = {
  customerType: PropTypes.string,
  userFullName: PropTypes.string,
  getAvailableBalance: PropTypes.func,
  accountsOptions: PropTypes.array,
  sendCreateCardRequest: PropTypes.func,
  isCardCreateConfirmation: PropTypes.bool,
  error: PropTypes.any,
  clearError: PropTypes.func,
  onConfirm: PropTypes.func,
  resendSecurityCode: PropTypes.func,
  dashboardSelectedAccountNumber: PropTypes.string
};
