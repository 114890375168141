import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { TWO_FACTOR_AUTH_TYPES } from 'components/common/constants';
import { ROUTE_PATHS } from 'routes/constants';

export const AuthorizationBackButton = ({ type, confirmationType, isGoogle2FAAuth = false, handle2Fa }) => {
  const i18n = useContext(i18nContext);

  return (
    <>
      {type === 'register' ? (
        <div className='back-button unset'>
          <p>
            {i18n.getMessage('login.label.noAccount')}
            <a href={ROUTE_PATHS.REGISTRATION} className='link-normal'>
              {i18n.getMessage('login.link.register')}
            </a>
          </p>
        </div>
      ) : confirmationType === TWO_FACTOR_AUTH_TYPES.SMS && isGoogle2FAAuth ? (
        <div className='back-button unset flex-column'>
          <p>
            <button onClick={handle2Fa} className='link-normal link-button'>
              {i18n.getMessage('login.2fa.use1')}
            </button>
            {i18n.getMessage('login.2fa.use2')}
          </p>
          <p>
            {i18n.getMessage('pin.returnToLogIn')}
            <a href={ROUTE_PATHS.LOGIN} className='link-normal'>
              {i18n.getMessage('login.title')}
            </a>
          </p>
        </div>
      ) : (
        <div className='back-button unset flex-column'>
          <p>
            {i18n.getMessage('pin.returnToLogIn')}
            <a href={ROUTE_PATHS.LOGIN} className='link-normal'>
              {i18n.getMessage('login.title')}
            </a>
          </p>
        </div>
      )}
    </>
  );
};

AuthorizationBackButton.propTypes = {
  type: PropTypes.string,
  isGoogle2FAAuth: PropTypes.bool,
  confirmationType: PropTypes.string,
  handle2Fa: PropTypes.func
};
