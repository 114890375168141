/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import BottomPartForm from './BottomPartForm';
import { openPaydAdvancedModel } from './Models';
import { ReactComponent as IbanIcon } from 'assets/globe.svg';
import { ReactComponent as NonIbanIcon } from 'assets/temple.svg';
import { CURRENCY_TYPES, RECIPIENT_TYPES, PAYMENT_METHOD, PAYMENT_PROVIDERS } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue, prepareFieldErrors } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';

const IBAN_TAB = 'IBAN';
const NON_IBAN_TAB = 'NON_IBAN';

const OpenPaydAdvancedForm = ({
  customerNumber,
  getCommission,
  iban,
  setIban,
  setIsNonIban,
  checkIban,
  isIbanCheckLoading,
  ibanCredentials,
  isRepeatPayment,
  clearIbanCredentials,
  uploadDocuments,
  validationSchema,
  paymentMethod,
  account,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  setError,
  isCommissionLoading,
  isLoading,
  isSuccess,
  commonForm,
  countries,
  providerType
}) => {
  const i18n = useContext(i18nContext);
  const form = useFormik(validationSchema);
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState(
    validationSchema.initialValues?.iban || account.multi_wallet_number || paymentMethod === PAYMENT_METHOD.SWIFT
      ? IBAN_TAB
      : NON_IBAN_TAB
  );
  const [showMethodCommission, setShowMethodCommission] = useState(false);
  const model = openPaydAdvancedModel;
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    validateField,
    validateForm,
    resetForm,
    setFieldValue,
    setErrors
  } = commonForm || form;

  useEffect(() => {
    if (activeTab === NON_IBAN_TAB) {
      setIsNonIban(true);
    } else {
      setIsNonIban(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (isRepeatPayment && !iban) {
      setActiveTab(NON_IBAN_TAB);
    }
  }, [isRepeatPayment, iban]);

  useEffect(() => {
    handleGetCommission(undefined);
  }, [paymentMethod]);

  useEffect(() => {
    if (iban && !values.recipient_wallet) {
      setFieldValue('recipient_wallet', iban);
    }
  }, [iban, values.recipient_wallet]);

  useEffect(() => {
    if (!iban && !ibanCredentials) {
      resetForm();
    } else {
      handleIbanComplete(false);
    }
  }, [account.wallet_number]);

  useEffect(() => {
    if (
      (!isIbanCheckLoading && ibanCredentials) ||
      (isRepeatPayment && !values.recipient_wallet && activeTab === NON_IBAN_TAB)
    ) {
      handleGetCommission(undefined);
    }
  }, [isIbanCheckLoading, ibanCredentials, isRepeatPayment, activeTab]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setDisabled(true);
    }
  }, [isSuccess]);

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });

  const handleGetCommission = async (fieldName) => {
    if (fieldName) await validateField(fieldName);
    if (
      !serverFieldsErrors?.iban &&
      (activeTab === IBAN_TAB
        ? values.recipient_wallet &&
          (ibanCredentials?.bank_country || values.bank_country) &&
          (ibanCredentials?.bank_name || values.bank_name) &&
          (ibanCredentials?.account_number || values.account_number) &&
          (ibanCredentials?.bic || values.bic)
        : values.bank_country && values.bank_name && values.account_number && values.bic) &&
      values.registration_number &&
      values.recipient_type &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY
        ? values.company_name
        : values.first_name && values.last_name) &&
      values.registration_number &&
      values.beneficiary_city &&
      values.beneficiary_country &&
      values.beneficiary_post_code &&
      values.beneficiary_address &&
      values.amount &&
      values.comment
    ) {
      setDisabled(false);
      setShowMethodCommission(true);
      const errorsResult = await validateForm();

      if (!Object.keys(errorsResult).length) {
        await getCommission(
          customerNumber,
          model({
            providerTableData: values,
            data: { wallet: account, paymentMethod, ...ibanCredentials }
          })
        );
      }
    }
  };

  const handleActiveTab = (activeTab) => {
    if (ibanCredentials) clearIbanCredentials();
    if (error) setError(null);
    if (activeTab === NON_IBAN_TAB) {
      setFieldValue('recipient_wallet', '');
      delete errors['recipient_wallet'];
    }
    if (activeTab === IBAN_TAB) {
      setFieldValue('bank_country', null);
      setFieldValue('bank_name', '');
      setFieldValue('account_number', '');
      setFieldValue('bic', '');
      delete errors['bank_country'];
      delete errors['bank_name'];
      delete errors['account_number'];
      delete errors['bic'];
    }
    setErrors(errors);
    setActiveTab(activeTab);
  };

  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
    if (e.target.name === 'recipient_wallet') {
      setIban(e.target.value);
    }
  };

  const getCommissionValue = () => {
    if (!commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }

    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleIbanComplete = async (causedOnBlur) => {
    if (causedOnBlur) await validateField('recipient_wallet');
    values.recipient_wallet && (await checkIban(values.recipient_wallet));
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    currency: account.currency,
    paymentMethod,
    paymentProvider: account.transfer_provider,
    isSubmitDisabled:
      disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !!error ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      {(account.currency !== CURRENCY_TYPES.EUR ||
        (providerType === PAYMENT_PROVIDERS.MANUAL && PAYMENT_METHOD.SWIFT)) && (
        <div className={'navigation-payment'}>
          <IconButton
            className={'navigation-payment-button'}
            text={i18n.getMessage('transfer.label.iban')}
            active={activeTab === IBAN_TAB}
            onClick={() => handleActiveTab(IBAN_TAB)}
            Icon={IbanIcon}
          />
          <IconButton
            className={'navigation-payment-button'}
            text={i18n.getMessage('transfer.divider.advanceForm')}
            active={activeTab === NON_IBAN_TAB}
            onClick={() => !isIbanCheckLoading && handleActiveTab(NON_IBAN_TAB)}
            Icon={NonIbanIcon}
          />
        </div>
      )}
      {activeTab === IBAN_TAB && (
        <Input
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.recipientIban.label')}
          name={'recipient_wallet'}
          value={values.recipient_wallet}
          error={errors.recipient_wallet || serverFieldsErrors.iban}
          onChange={onAmountIBANChange}
          onBlur={() => handleIbanComplete(true)}
          subText={
            ibanCredentials?.bank_name &&
            i18n.getMessage('transfer.form.bankName.subText', { bankName: ibanCredentials?.bank_name })
          }
        />
      )}
      {(activeTab === NON_IBAN_TAB || activeTab === IBAN_TAB) && paymentMethod === PAYMENT_METHOD.SWIFT && (
        <>
          <div className={'inputs-wrapper-evenly'}>
            <InputDropDownSearch
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bank_country}
              autoComplete={'off'}
              label={i18n.getMessage('transfer.form.bankCountry.label')}
              name={'bank_country'}
              value={ibanCredentials?.bank_country || values.bank_country}
              onChange={handleRecipientTypeChange}
              options={countries.map((value) => ({ key: value, value: i18n.getMessage(`country.${value}`) }))}
              error={errors.bank_country || serverFieldsErrors.bank_country}
              onBlur={() => handleGetCommission('bank_country')}
            />
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bank_name}
              autoComplete={'off'}
              label={i18n.getMessage('transfer.form.bankName.label')}
              name={'bank_name'}
              value={ibanCredentials?.bank_name || values.bank_name}
              onChange={handleChange}
              error={errors.bank_name || serverFieldsErrors.bank_name}
              onBlur={() => handleGetCommission('bank_name')}
            />
          </div>
          <div className={'inputs-wrapper-evenly'}>
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.account_number}
              autoComplete={'off'}
              label={i18n.getMessage('transfer.form.accountNumber.label')}
              name={'account_number'}
              value={ibanCredentials?.account_number || values.account_number}
              onChange={handleChange}
              error={errors.account_number || serverFieldsErrors.accountNumber}
              onBlur={() => handleGetCommission('account_number')}
            />
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bic}
              autoComplete={'off'}
              label={i18n.getMessage('transfer.form.BIC.label')}
              error={errors?.bic || serverFieldsErrors?.bic}
              name={'bic'}
              value={ibanCredentials?.bic || values.bic}
              onChange={handleChange}
              onBlur={() => handleGetCommission('bic')}
            />
          </div>
        </>
      )}
      <div className={`inputs-wrapper ${values.recipient_type}`}>
        <InputDropDown
          label={i18n.getMessage('transfer.form.recipientType.label')}
          autoComplete={'off'}
          name={'recipient_type'}
          value={values.recipient_type}
          onChange={handleRecipientTypeChange}
          options={Object.values(RECIPIENT_TYPES).map((value) => ({ key: value, value }))}
          isDisabled={isIbanCheckLoading}
        />
        {values.recipient_type === RECIPIENT_TYPES.COMPANY && (
          <Input
            isRequired={true}
            autoComplete={'off'}
            label={i18n.getMessage('transfer.form.companyName.label')}
            name={'company_name'}
            value={values.company_name}
            error={errors.company_name || serverFieldsErrors.companyName}
            onChange={handleChange}
            onBlur={() => handleGetCommission('company_name')}
            isDisabled={isIbanCheckLoading}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            autoComplete={'off'}
            label={i18n.getMessage('transfer.form.firstName.label')}
            name={'first_name'}
            value={values.first_name}
            error={errors.first_name || serverFieldsErrors.firstName}
            onChange={handleChange}
            onBlur={() => handleGetCommission('first_name')}
            isDisabled={isIbanCheckLoading}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            autoComplete={'off'}
            label={i18n.getMessage('transfer.form.lastName.label')}
            name={'last_name'}
            value={values.last_name}
            error={errors.last_name || serverFieldsErrors.lastName}
            onChange={handleChange}
            onBlur={() => handleGetCommission('last_name')}
            isDisabled={isIbanCheckLoading}
          />
        )}
      </div>
      <div className={'inputs-wrapper-evenly'}>
        <Input
          isRequired={true}
          autoComplete={'off'}
          label={
            values.recipient_type === RECIPIENT_TYPES.COMPANY
              ? i18n.getMessage('transfer.form.registrationNumber.label')
              : i18n.getMessage('transfer.form.identificationNumber.label')
          }
          name={'registration_number'}
          value={values.registration_number}
          onChange={handleChange}
          error={errors.registration_number || serverFieldsErrors?.registrationNumber}
          onBlur={() => handleGetCommission('registration_number')}
          isDisabled={isIbanCheckLoading}
        />
        <Input
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.beneficiaryCity.label')}
          name={'beneficiary_city'}
          value={values.beneficiary_city}
          onChange={handleChange}
          error={errors.beneficiary_city || serverFieldsErrors.beneficiary_city}
          onBlur={() => handleGetCommission('beneficiary_city')}
          isDisabled={isIbanCheckLoading}
        />
      </div>
      <div className={'inputs-wrapper-evenly'}>
        <InputDropDownSearch
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.beneficiaryCountry.label')}
          name={'beneficiary_country'}
          value={values.beneficiary_country}
          onChange={handleRecipientTypeChange}
          options={countries.map((value) => ({ key: value, value: i18n.getMessage(`country.${value}`) }))}
          error={errors.beneficiary_country || serverFieldsErrors.beneficiary_country}
          onBlur={() => handleGetCommission('beneficiary_country')}
          isDisabled={isIbanCheckLoading}
        />
        <Input
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.beneficiaryPostalCode.label')}
          name={'beneficiary_post_code'}
          value={values.beneficiary_post_code}
          onChange={handleChange}
          error={errors.beneficiary_post_code || serverFieldsErrors.beneficiary_post_code}
          onBlur={() => handleGetCommission('beneficiary_post_code')}
          isDisabled={isIbanCheckLoading}
        />
      </div>
      <Input
        isRequired={true}
        autoComplete={'off'}
        label={i18n.getMessage('transfer.form.beneficiaryAddress.label')}
        name={'beneficiary_address'}
        value={values.beneficiary_address}
        onChange={handleChange}
        error={errors.beneficiary_address || serverFieldsErrors.beneficiary_address}
        onBlur={() => handleGetCommission('beneficiary_address')}
        isDisabled={isIbanCheckLoading}
      />
      <BottomPartForm paymentProvider={account.transfer_provider} {...bottomPartProps} />
    </>
  );
};

OpenPaydAdvancedForm.propTypes = {
  customerNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  iban: PropTypes.string,
  setIban: PropTypes.func,
  setIsNonIban: PropTypes.func,
  checkIban: PropTypes.func.isRequired,
  ibanCredentials: PropTypes.object,
  clearIbanCredentials: PropTypes.func,
  paymentMethod: PropTypes.string,
  isRepeatPayment: PropTypes.bool,
  isIbanCheckLoading: PropTypes.bool,
  validationSchema: PropTypes.object,
  account: PropTypes.object,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  setError: PropTypes.func,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool,
  countries: PropTypes.array,
  providerType: PropTypes.string
};

export default OpenPaydAdvancedForm;
