import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'assets/pop-up-close-icon.svg';
import i18nContext from 'components/i18n-context';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { CONFIRMATION_HANDLE_TYPES, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { isFullSecurityCode } from 'components/utils';
import { getErrorMessageForAlert } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpGoogle2FAScheme = ({ userStore, onClose }) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  const [isDisabled, setIsDisabled] = useState(false);
  const [securityCode, setSecurityCode] = useState('');

  const handleCode = (code, disableSend) => {
    if (!disableSend) {
      userStore.confirmGoogle2FAEnabling(code);
    }
  };

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(userStore.error?.code)) {
      setIsDisabled(true);
      handleCode(securityCode, true);
    }
    userStore.error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, userStore.error) });
  }, [userStore.error]);

  const onComplete = (sms) => {
    setSecurityCode(sms);
    setAlertState({ type: '', message: '' });
  };

  const handleNextButton = () => {
    handleCode(securityCode);
  };

  const handleSwitchToSmsAuth = () => userStore.forceSwitchToSmsConfirmation();

  return (
    <div className='scheme-wrapper'>
      <div className='scheme-header'>
        <p className='scheme-header-text'>{i18n.getMessage('popUp.google2fa.title')}</p>
        <CloseIcon className='scheme-close-icon' onClick={!userStore.isGoogle2FALoading ? onClose : null} />
      </div>
      <div className='scheme-instructions-wrapper'>
        <ol>
          <li className='scheme-instruction-text'>{i18n.getMessage('popUp.google2fa.instruction1')}</li>
          <li className='scheme-instruction-text'>{i18n.getMessage('popUp.google2fa.instruction2')}</li>
          <li className='scheme-instruction-text'>{i18n.getMessage('popUp.google2fa.instruction3')}</li>
        </ol>
      </div>
      <div className='scheme-qr-code-wrapper'>
        <img className='scheme-qr-code-image' src={userStore.googleQrUrl} alt={userStore.googleQrUrl} />
      </div>
      <ConfirmCodeWrapper
        type={'google'}
        isDisabled={isDisabled}
        error={userStore.error}
        onComplete={onComplete}
        alertState={alertState}
        handleSwitchToSmsAuth={handleSwitchToSmsAuth}
        isGoogle2FASignUpPopUp={true}
        confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
      />
      <Button
        className='scheme-next-button'
        fullWidth={true}
        onClick={handleNextButton}
        isDisabled={!isFullSecurityCode(securityCode) || userStore.isGoogle2FALoading}
      >
        {i18n.getMessage('popUp.google2fa.button')}
      </Button>
    </div>
  );
};

PopUpGoogle2FAScheme.propTypes = {
  onClose: PropTypes.func.isRequired,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(PopUpGoogle2FAScheme));
