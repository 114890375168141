import { useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import VerificationTypeSelector from './VerificationTypeSelector';
import { CUSTOMER_TYPE, KYC_PROVIDERS, USER_CUSTOMER_STATUSES, VERIFICATION_TYPES } from 'components/common/constants';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import Shufti from 'components/Verification/Shufti';
import Sumsub from 'components/Verification/Sumsub';
import './Verification.scss';

const Verification = ({ userStore }) => {
  const customerNumber = userStore?.userData.account?.account_number;
  const [applicantLevel, setApplicantLevel] = useState(
    userStore?.userData?.account?.kyc_level_name
      ? userStore?.userData?.account?.kyc_level_name
      : userStore?.userData.account?.type === CUSTOMER_TYPE.INDIVIDUAL
      ? VERIFICATION_TYPES.BASIC
      : null
  );

  const kycProvider = process.env.REACT_APP_CORPORATE_KYC_PROVIDER;

  const beginVerification = async (customerNumber, data) => await userStore?.beginVerification(customerNumber, data);

  if (userStore?.userData.account?.status === USER_CUSTOMER_STATUSES.DECLINED) {
    return <NoOutlet error={{ code: USER_CUSTOMER_STATUSES.DECLINED }} verificationType={'TEST'} />;
  }
  if (userStore?.userData.account?.status === USER_CUSTOMER_STATUSES.CLOSED) {
    return <NoOutlet error={{ code: USER_CUSTOMER_STATUSES.CLOSED }} verificationType={'TEST'} />;
  }

  if (!applicantLevel && userStore?.userData.account?.type === CUSTOMER_TYPE.COMPANY) {
    return <VerificationTypeSelector setApplicantLevel={setApplicantLevel} />;
  }

  return (
    <>
      {kycProvider === KYC_PROVIDERS.SHUFTI && <Shufti customerNumber={customerNumber} />}
      {kycProvider === KYC_PROVIDERS.SUMSUB && (
        <Sumsub
          email={userStore?.userData.account?.email}
          phone={userStore?.userData.phone}
          customerNumber={customerNumber}
          levelName={applicantLevel}
          accessToken={userStore?.verificationAccessToken}
          beginVerification={beginVerification}
        />
      )}
    </>
  );
};

Verification.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Verification));
