/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Login from './Login';
import Sms from './Sms';
import Google2FA from './Google2FA';
import { TWO_FACTOR_AUTH_TYPES } from 'components/common/constants';
import { INITIAL_PATH_AFTER_LOGIN, ROUTE_PATHS } from 'routes/constants';
import { clearLocalStorage } from 'services/authUtils';

const LoginStages = ({ authStore }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = () => {
      if (authStore.isLoginSuccess && location.pathname === ROUTE_PATHS.LOGIN) {
        authStore.reset();
        clearLocalStorage();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!authStore.isLoading && authStore.isLoginSuccess && authStore.isPinSuccess) {
      navigate(INITIAL_PATH_AFTER_LOGIN, { replace: true });
      authStore.reset();
    }
  }, [authStore.isLoading, authStore.isLoginSuccess, authStore.isPinSuccess]);

  const handleSetPhone = (phone) => authStore.setPhone(phone);

  const handleSetPassword = (password) => authStore.setPassword(password);

  const resendSecurityCode = () => authStore.resendSecurityCode();

  const handleCode = (code, disableSend) => {
    authStore.setCode(code);
    if (!disableSend) {
      authStore.sendCode();
    }
  };

  const handleSendAuthorizationData = async () => await authStore.sendAuthorizationData();

  const showSecondStep = () => {
    if (authStore.confirmationActionType === TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH) {
      return (
        <Google2FA
          sms={authStore.values.code}
          error={authStore.error}
          handleSms={handleCode}
          resendCode={resendSecurityCode}
        />
      );
    } else {
      return (
        <Sms
          phone={authStore.phone}
          sms={authStore.values.code}
          error={authStore.error}
          handleSms={handleCode}
          resendCode={resendSecurityCode}
        />
      );
    }
  };

  return (
    <div>
      {authStore.isLoginSuccess ? (
        showSecondStep()
      ) : (
        <Login
          phone={authStore.values.phone}
          password={authStore.values.password}
          setPhone={handleSetPhone}
          setPassword={handleSetPassword}
          sendAuthorizationData={handleSendAuthorizationData}
          error={authStore.error}
          isLoading={authStore.isLoading}
        />
      )}
    </div>
  );
};

LoginStages.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(LoginStages));
