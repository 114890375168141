/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import { ReactComponent as StandardIcon } from 'assets/standard-icon.svg';
import i18nContext from 'components/i18n-context';
import { VERIFICATION_TYPES } from 'components/common/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import './Verification.scss';

const VerificationTypeSelector = ({ setApplicantLevel }) => {
  const i18n = useContext(i18nContext);

  const handleVerificationTypeSelect = (level) => {
    setApplicantLevel(level);
  };

  return (
    <section className='right-section'>
      <Container className='verification-container' header={i18n.getMessage('container.verification')}>
        <div className='verification-selector-wrapper'>
          <Button
            className='verification-selector-button'
            type='outline'
            onClick={() => handleVerificationTypeSelect(VERIFICATION_TYPES.SIMPLE)}
          >
            <SimpleIcon />
            <span className='verification-selector-button-title'>
              {i18n.getMessage('verification.selectType.simple.title')}
            </span>
            <span className='verification-selector-button-description'>
              {i18n.getMessage('verification.selectType.simple.description')}
            </span>
          </Button>
          <Button
            className='verification-selector-button'
            type='outline'
            onClick={() => handleVerificationTypeSelect(VERIFICATION_TYPES.STANDARD)}
          >
            <StandardIcon />
            <span className='verification-selector-button-title'>
              {i18n.getMessage('verification.selectType.standard.title')}
            </span>
            <span className='verification-selector-button-description'>
              {i18n.getMessage('verification.selectType.standard.description')}
            </span>
          </Button>
        </div>
      </Container>
    </section>
  );
};

VerificationTypeSelector.propTypes = {
  setApplicantLevel: PropTypes.func
};

export default VerificationTypeSelector;
