import { replaceSpaces, strToFloat } from 'services/utils';
import { PAYMENT_METHOD } from 'components/common/constants';

export default ({ providerTableData, data }) => ({
  sender_wallet: (data.wallet && data.wallet?.wallet_number?.trim()) || '',
  currency: (data.wallet && data.wallet?.currency?.trim()) || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  transaction_recipient: {
    beneficiary: {
      type: providerTableData.recipient_type.toUpperCase(),
      ...(providerTableData.recipient_type === 'Company'
        ? {
            company_name: providerTableData?.company_name?.trim() || ''
          }
        : {
            first_name: providerTableData?.first_name?.trim() || '',
            last_name: providerTableData?.last_name?.trim() || ''
          })
    },
    requisites: providerTableData?.recipient_wallet
      ? {
          iban: replaceSpaces(providerTableData?.recipient_wallet) || '',
          bank_country: data?.bank_country?.trim()
        }
      : {
          account_number: providerTableData?.account_number?.trim(),
          sort_code: providerTableData?.sort_code?.trim().replace(/-/g, ''),
          bank_country: process.env.REACT_APP_DEFAULT_COUNTRY_CODE
        }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: (data.wallet && data.wallet?.transfer_provider) || '',
  comment:
    data.paymentMethod === PAYMENT_METHOD.SEPA ||
    data.paymentMethod === PAYMENT_METHOD.SEPA_INSTANT ||
    data.paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS
      ? providerTableData?.comment || ''
      : providerTableData?.comment?.trim() || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
