import { makeAutoObservable, runInAction } from 'mobx';
import { BACKEND_MODULE_TYPES } from 'components/common/constants';
import { updateConfirmationActionType } from 'services/authUtils';
import {
  createExchangeQuoteRequest,
  calculateExchangeDataRequest,
  createExchangeTransferRequest,
  confirmExchangeTransferRequest,
  resendSecurityCode
} from 'services/requestAgent';

class CurrencyExchangeStore {
  isRepeatTransaction = false;
  isRedirectFromPaymentForm = false;
  isExchangeLoading = false;
  isCalculatedFromToValueAndCommission = false;
  isExchangeTransferCreated = false;
  isExchangeSucceeded = false;
  error = null;
  confirmationPopupError = null;
  accountFrom = null;
  accountTo = null;
  amountFrom = null;
  amountTo = null;
  exchangeData = {
    quoteId: null,
    rate: null,
    commission: null,
    expiresAt: null,
    duration: null
  };
  createdExchangeTransferData = {
    confirmationId: null,
    rate: null,
    commission: null,
    accountFrom: null,
    accountTo: null,
    amountFrom: null,
    amountTo: null
  };
  fieldsAreDisabled = false;

  constructor() {
    makeAutoObservable(this);
  }

  resetExchangeStore() {
    this.isRepeatTransaction = false;
    this.isRedirectFromPaymentForm = false;
    this.isExchangeLoading = false;
    this.isCalculatedFromToValueAndCommission = false;
    this.isExchangeTransferCreated = false;
    this.isExchangeSucceeded = false;
    this.error = null;
    this.confirmationPopupError = null;
    this.accountFrom = null;
    this.accountTo = null;
    this.amountFrom = null;
    this.amountTo = null;
    this.exchangeData = {
      quoteId: null,
      rate: null,
      commission: null,
      expiresAt: null,
      duration: null
    };
    this.createdExchangeTransferData = {
      confirmationId: null,
      rate: null,
      commission: null,
      accountFrom: null,
      accountTo: null,
      amountFrom: null,
      amountTo: null
    };
  }

  setIsRepeatTransactionStatus(status) {
    this.isRepeatTransaction = status;
  }

  setIsRedirectFromPaymentFormStatus(status) {
    this.isRedirectFromPaymentForm = status;
  }

  setFromToAccounts(from, to) {
    this.accountFrom = from;
    this.accountTo = to;
    this.error = null;
  }

  setAmount(amountType, value) {
    this[amountType] = value;
  }

  clearAmounts() {
    this.amountFrom = null;
    this.amountTo = null;
    this.isCalculatedFromToValueAndCommission = false;
    this.exchangeData = {
      ...this.exchangeData,
      commission: null
    };
  }

  setIsExchangeLoading(status) {
    this.isExchangeLoading = status;
    this.error = null;
    this.fieldsAreDisabled = false;
  }

  resetAfterSuccessfulExchange() {
    this.confirmationPopupError = null;
    this.amountFrom = null;
    this.amountTo = null;
    this.isExchangeSucceeded = false;
    this.createdExchangeTransferData = {
      confirmationId: null,
      rate: null,
      commission: null,
      accountFrom: null,
      accountTo: null,
      amountFrom: null,
      amountTo: null
    };
  }

  resetCreatedExchangeTransfer() {
    this.isExchangeTransferCreated = false;
    this.createdExchangeTransferData = {
      confirmationId: null,
      rate: null,
      commission: null,
      accountFrom: null,
      accountTo: null,
      amountFrom: null,
      amountTo: null
    };
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  async createExchangeQuote(customerNumber) {
    try {
      this.setIsExchangeLoading(true);

      const {
        quote_id: quoteId,
        rate,
        expiresAt,
        duration
      } = await createExchangeQuoteRequest(customerNumber, {
        wallet_from: this.accountFrom?.wallet_number,
        wallet_to: this.accountTo?.wallet_number
      });

      runInAction(() => {
        this.exchangeData = {
          ...this.exchangeData,
          quoteId,
          duration,
          rate,
          expiresAt
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        if (
          err.code === 'EXCHANGE_PAIR_DISABLED' ||
          err.code === 'EXCHANGE_RATE_NOT_FOUND' ||
          err.code === 'PAYMENT_CREATION_TIME_EXPIRED'
        ) {
          this.fieldsAreDisabled = true;
        }
      });
    } finally {
      runInAction(() => {
        this.isExchangeLoading = false;
      });
    }
  }

  async calculateExchangeData(customerNumber, amount, direction) {
    try {
      this.setIsExchangeLoading(true);

      const {
        from: amountFrom,
        to: amountTo,
        commission
      } = await calculateExchangeDataRequest(customerNumber, {
        quote_id: this.exchangeData.quoteId,
        amount,
        direction
      });

      runInAction(() => {
        this.amountFrom = amountFrom;
        this.amountTo = amountTo;
        this.exchangeData = {
          ...this.exchangeData,
          commission
        };
        this.isCalculatedFromToValueAndCommission = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isCalculatedFromToValueAndCommission = false;
      });
    } finally {
      runInAction(() => {
        this.isExchangeLoading = false;
      });
    }
  }

  async createExchangeTransfer(customerNumber) {
    try {
      this.setIsExchangeLoading(true);

      const { confirmation_id: confirmationId, confirmation_type: confirmationType } =
        await createExchangeTransferRequest(customerNumber, {
          quote_id: this.exchangeData.quoteId,
          amount: this.amountFrom
        });

      updateConfirmationActionType(confirmationType);

      runInAction(() => {
        this.createdExchangeTransferData = {
          confirmationId: confirmationId,
          rate: this.exchangeData.rate,
          commission: this.exchangeData.commission,
          accountFrom: this.accountFrom,
          accountTo: this.accountTo,
          amountFrom: this.amountFrom,
          amountTo: this.amountTo
        };
        this.isExchangeTransferCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isExchangeTransferCreated = false;
      });
    } finally {
      runInAction(() => {
        this.isExchangeLoading = false;
      });
    }
  }

  async confirmExchangeTransfer(customerNumber, code) {
    try {
      this.setIsExchangeLoading(true);

      await confirmExchangeTransferRequest(customerNumber, {
        confirmation_id: this.createdExchangeTransferData.confirmationId,
        code
      });

      runInAction(() => {
        this.isExchangeTransferCreated = false;
        this.isExchangeSucceeded = true;
      });
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
        this.isExchangeSucceeded = false;
      });
    } finally {
      runInAction(() => {
        this.isExchangeLoading = false;
      });
    }
  }

  async resendExchangeSecurityCode() {
    try {
      this.setIsExchangeLoading(false);
      await resendSecurityCode(BACKEND_MODULE_TYPES.PAYMENT, this.createdExchangeTransferData.confirmationId);
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    } finally {
      runInAction(() => {
        this.isExchangeLoading = false;
      });
    }
  }
}

export default new CurrencyExchangeStore();
