import { MAX_UPLOADED_FILES_SIZE } from 'components/common/constants';
import { FieldError } from 'services/Error';

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!\"#$%&'()*+,-.:;<=>?@\[\\\]^_`{|}~])[\x20-\x7E]{7,30}$/;

export const messageRegExp = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s]*$/;

export const replaceSpaces = (s) => s?.replace(/\s/g, '');

export const deleteNonLatin = (text) => text.replace(/[^A-Za-z0-9_ ]/gi, '');

export const formatSortCode = (string) => string?.replace(/^(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

export const convertBytesToMegabytes = (bytes) => bytes / (1024 * 1024);

export const strToFloat = (str) => {
  const trimmed = str.trim().replace(/\.$/, '');
  const float = parseFloat(trimmed);

  return float === Number(trimmed) ? float : Number.NaN;
};

const extractPaymentProviderFromErrorMessage = (errorMessage) => {
  const regex = /\[([^\]]+)\]/;
  const match = regex.exec(errorMessage);
  if (match && match.length > 1) {
    const bracketContent = match[1];
    const values = bracketContent.split(',');
    if (values.length > 0) {
      return values[0].trim();
    }
  }
  return null;
};

export const getErrorMessageForAlert = (i18n, error, isIgnored = true) => {
  let translation = '';
  if (error instanceof FieldError && isIgnored) return translation;

  const translationId = `error.${error?.code}`;
  if (error?.code === 'REQUEST_HAS_BEEN_TERMINATED') {
    translation = i18n.getMessage(translationId, { maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE) });
  } else if (error?.code === 'AMOUNT_CHECK_FAILED') {
    const extractedPaymentMethod = extractPaymentProviderFromErrorMessage(error.message);
    translation = i18n.getMessage(`${translationId}.${extractedPaymentMethod}`);
  } else if (error?.code === 'PAYYE_CHECK_FAILED') {
    translation = error.message;
  } else if (error?.code === 'PAYMENT_CREATION_TIME_EXPIRED') {
    translation = i18n.getMessage('error.PAYMENT_CREATION_TIME_EXPIRED');
  } else if (error?.code === 'DOCUMENT_PROTECTED_WITH_PASSWORD') {
    translation = i18n.getMessage('error.DOCUMENT_PROTECTED_WITH_PASSWORD');
  } else if (error?.status === 504) {
    translation = i18n.getMessage('error.GETAWAY_TIME_OUT');
  } else if (error?.message === 'Unsuccessful HTTP response') {
    translation = i18n.getMessage('error.UNSUCCESSFUL_HTTP_RESPONSE');
  } else if (error?.message === 'Server Error') {
    translation = i18n.getMessage('error.SYSTEM_ERROR');
  } else {
    translation = i18n.getMessage(translationId);
  }
  if (translationId === translation) {
    return error?.message || error?.code;
  } else {
    return translation;
  }
};

export const prepareFieldErrors = (i18n, error, variable) => {
  let fieldsError = {};

  if (
    error?.fields &&
    error?.fields[0]?.code &&
    (error?.fields[0]?.code === 'NO_PAYMENT_METHODS_AVAILABLE' ||
      error?.fields[0]?.code === 'EXTERNAL_IBAN_VALIDATION_FAILED')
  ) {
    fieldsError = { iban: i18n.getMessage(`error.fieldError.${error?.fields[0].field}.${error?.fields[0].code}`) };
  } else {
    error?.fields &&
      Object.keys(error.fields).map((field) => {
        // eslint-disable-next-line max-len
        fieldsError[field.split('.').pop()] = i18n.getMessage(`error.fieldError.${field}.${error.fields[field]}`, {
          variable
        });
      });
  }

  return fieldsError;
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  HEADER_BUTTON_NAMES: '(min-width: 1050px)',
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const convertDateToServerFilter = (date) => {
  if (date !== null) {
    return `${date.getFullYear()}${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }`;
  } else {
    return '';
  }
};

export const convertDate = (date, isEndOfDay = false) => {
  if (date !== null) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }T${isEndOfDay ? '23:59:59' : '00:00:00'}`;
  }
  return '';
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  let stringValue = value.toString();

  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));
};

export const findAccountByCurrency = ({ accounts, currency, currencyForExclude }) => {
  if (!currency) {
    return accounts.find((account) => account.currency !== currencyForExclude);
  }
  return accounts.reduce((result, account) => {
    if (account.currency === currencyForExclude) {
      return undefined;
    }
    return account.currency === currency;
  }, undefined);
};

// FEES DATA FORMAT FUNCTIONS

export const format = (val) => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formServiceName = (obj) => {
  return format(obj.name);
};

export const formPaymentMethod = (obj) => {
  if (obj.transfer_type === 'EXCHANGE') {
    return format(obj.transfer_type);
  } else {
    return format(obj.payment_method);
  }
};

export const formTransactionType = (obj) => {
  if (obj.transfer_type === 'RETURN') {
    const capitalizedTransactionType = format(obj.transaction_type);
    const capitalizedTransferType = format(obj.transfer_type);
    return `${capitalizedTransactionType} ${capitalizedTransferType}`;
  } else {
    return format(obj.transaction_type);
  }
};

export function fileExtensionValidation(fileName) {
  const extension = fileName.split('.').pop();
  const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

  return allowedExtensions.includes(extension);
}
