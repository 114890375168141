import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import RichTextEditor from 'react-rte';
import { ReactComponent as IconPassword } from './eye.svg';
import { ReactComponent as IconPasswordOff } from './eye-off.svg';
import { ReactComponent as IconError } from './warning.svg';
import { classNames } from '../utils';
import './Input.scss';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

const Input = ({
  type = 'text',
  name,
  error,
  isApiError,
  value,
  setValue,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  label,
  placeholder,
  subText,
  className,
  Icon,
  initialStatus,
  isDisabled,
  autoComplete = 'on',
  isRequired,
  autoFocus = false,
  max,
  rows,
  setCountryCode,
  excludeCountries = []
}) => {
  const [inputType, setInputType] = useState(type);
  const editorContainerRef = useRef();

  const toggleShowingPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const inputChange = (e) => {
    if (name === 'comment' && e.target.value.startsWith(' ') && e.target.value.trim() === '') {
      onChange({ ...e, target: { ...e.target, value: '' } });
    } else {
      onChange(e);
    }
  };

  const handleBlur = (e) => onBlur(e);

  const handleFocus = () => onFocus();

  const handleLinkClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && isDisabled) {
      e.preventDefault();
    }
  };

  const inputClasses = classNames({
    'input-wrapper': true,
    error: !!error || !!isApiError,
    hit: !error && !isApiError && !!initialStatus,
    disabled: isDisabled,
    icon: Icon || type === 'password' || error,
    [className]: true
  });

  const renderIcons = () => {
    if (Icon) {
      return <Icon className={'input-icon'} />;
    }

    if (type === 'password') {
      return (
        <div
          role={'button'}
          tabIndex={0}
          onKeyDown={toggleShowingPassword}
          onClick={toggleShowingPassword}
          className={`input-icon eye ${error ? 'error' : ''}`}
        >
          {inputType === 'text' ? <IconPassword /> : <IconPasswordOff />}
          {error && <IconError />}
        </div>
      );
    }

    return error && <IconError className={'input-icon'} />;
  };

  return type === 'phone' ? (
    <div className={inputClasses}>
      <PhoneInput
        /* eslint-disable-next-line max-len */
        containerClass={`custom-react-tel-input ${(error || isApiError) && 'invalid-tel-number'} ${
          !error && !isApiError && !!initialStatus && 'hit-tel-number'
        }`}
        country={process.env.REACT_APP_DEFAULT_COUNTRY_CODE.toLowerCase()}
        excludeCountries={excludeCountries}
        specialLabel={label + (isRequired ? '*' : '')}
        inputProps={{ name, autoFocus, required: isRequired }}
        value={value}
        onBlur={onBlur}
        onChange={(phoneNumber, countryData, e, formattedValue) => {
          if (e.type === 'click') {
            setValue(name, formattedValue);
          } else {
            onChange(e);
          }
          setCountryCode(countryData?.countryCode);
        }}
      />
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  ) : type === 'textarea' ? (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <textarea
        name={name}
        value={value || ''}
        onChange={inputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        maxLength={max}
        rows={rows}
        className={'input'}
      />
      {renderIcons()}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  ) : type === 'text-editor' ? (
    <div
      className={'text-editor-wrapper'}
      onClick={handleLinkClick}
      onKeyDown={handleEnterPress}
      tabIndex={0}
      role={isDisabled ? 'button' : ''}
    >
      <label className={'text-editor-label'} htmlFor={'message'}>
        {label}
      </label>
      <RichTextEditor
        name={name}
        className={`RichTextEditor ${isDisabled ? 'disabled' : ''}`}
        readOnly={isDisabled}
        autoFocus={autoFocus}
        toolbarConfig={toolbarConfig}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={editorContainerRef}
      />
      {(error || subText) && (
        <p className={`text-editor-sub-text ${error ? 'text-editor-sub-text-error' : ''}`}>{error || subText}</p>
      )}
    </div>
  ) : (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <input
        name={name}
        type={inputType}
        value={value || ''}
        onChange={inputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={isDisabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        maxLength={max}
        className={'input'}
      />
      {renderIcons()}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isApiError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.func,
  subText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  initialStatus: PropTypes.number,
  Icon: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number,
  rows: PropTypes.number,
  setCountryCode: PropTypes.func,
  excludeCountries: PropTypes.array
};

export default Input;
