import { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
import { CheckBox } from '../CheckBox/CheckBox';
import { classNames } from '../utils';
import { TRANSACTIONS_TABLE_STATUS } from 'components/common/constants';
import './InputDropDown.scss';

export const InputDropDown = ({
  options,
  specificOptions,
  name,
  error,
  value,
  currency,
  onChange,
  label = '',
  placeholder,
  subText,
  className,
  topLabel,
  isRequired,
  isMulti,
  isDisabled,
  isSpecificValue
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const inputClasses = classNames({
    'input-wrapper-dropdown': true,
    active: isMenuOpen || value?.length,
    disabled: isDisabled,
    error: error,
    'multi-select': isMulti,
    [className]: true
  });

  const getValue = () => {
    if (isSpecificValue) return value;

    if (isMulti) {
      const multiValue = options?.filter((option) => value?.includes(option.key));
      return multiValue
        .map((val) => {
          if (val.currency) {
            return val.currency + ' ' + val.value;
          } else {
            return val.value;
          }
        })
        .join('/');
    }
    return options?.find((option) => option?.key === value)?.value;
  };

  const inputChange = (data) => {
    if (isMulti) {
      let valueSet;
      if (name === 'statuses' && data === TRANSACTIONS_TABLE_STATUS.processing) {
        valueSet = value?.includes(data)
          ? (val) =>
              val !== data &&
              val !== TRANSACTIONS_TABLE_STATUS.acceptRequest &&
              val !== TRANSACTIONS_TABLE_STATUS.rejectRequest
          : [...value, data, TRANSACTIONS_TABLE_STATUS.acceptRequest, TRANSACTIONS_TABLE_STATUS.rejectRequest];
      } else {
        valueSet = value?.includes(data) ? value.filter((val) => val !== data) : [...value, data];
      }
      return onChange(name, valueSet);
    }
    onChange(name, data);
    setIsMenuOpen(false);
  };

  const toggleMenu = (e) => {
    if (options && !isDisabled && !isMenuOpen && e.key !== 'Escape') {
      setIsMenuOpen(true);
    } else if (e.key === 'Escape') {
      setIsMenuOpen(false);
    }
  };

  const closeMenu = (e) => {
    if (!e?.relatedTarget?.className.includes('dropdown-choice') && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div
      onBlur={(e) => closeMenu(e)}
      role={'button'}
      tabIndex={0}
      onKeyDown={toggleMenu}
      onClick={toggleMenu}
      className={inputClasses}
    >
      {topLabel && <p className={'top-label'}>{topLabel}</p>}
      <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>
      <input
        name={name}
        type={'text'}
        value={getValue() || ''}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        disabled
        className={`${currency ? 'wallet-input' : ''} input`}
      />

      {currency && <span className={'currency-label'}>{currency}</span>}
      <div className={`input-icon eye ${error ? 'error' : ''}`}>{isMenuOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
      {isMenuOpen && (
        <div className={'input-dropdown-menu'}>
          {options.map((dataPoint, index) => (
            <CheckBox
              key={index}
              onChange={() => inputChange(dataPoint.key)}
              isActive={(isMulti && value?.includes(dataPoint.key)) || dataPoint.key === value}
              showCheckBox={isMulti}
              value={dataPoint.value}
              currency={dataPoint.currency}
            />
          ))}
          {specificOptions &&
            specificOptions.map((dataPoint, index) => (
              <CheckBox
                key={index}
                onChange={() => inputChange(dataPoint.key)}
                isActive={(isMulti && value?.includes(dataPoint.key)) || dataPoint.key === value}
                showCheckBox={isMulti}
                value={null}
                content={dataPoint.content}
              />
            ))}
        </div>
      )}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  );
};

InputDropDown.propTypes = {
  options: PropTypes.array,
  specificOptions: PropTypes.array,
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  currency: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  topLabel: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSpecificValue: PropTypes.bool
};
