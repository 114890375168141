import { ReactComponent as LoadIcon } from './load-icon.svg';
import PropTypes from 'prop-types';
import './FileDownloadButton.scss';
import { classNames } from '../utils';

export const FileDownloadButton = ({ onClick, type, className, style, isDisabled }) => {
  const buttonClasses = classNames({
    'pdf-button': true,
    disabled: isDisabled,
    [className]: true,
    [style]: true
  });

  return (
    <button className={buttonClasses} onClick={onClick}>
      {type}
      <LoadIcon className='icon' />
    </button>
  );
};

FileDownloadButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  isDisabled: PropTypes.bool
};
